@import "../../../../assets/scss/variables";
.feedback {
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0.2s linear;
  visibility: hidden;
  &.open {
    background-color: rgba(0, 0, 0, 0.8);
    visibility: visible;
    .inner {
      transform: translate(0, 0);
    }
  }
  .inner {
    border-radius: 16px 16px 0 0;
    padding: 16px;
    transform: translate(0, 100%);
    transition: 0s linear;
    display: flex;
    flex-direction: column;
    background: $backgroundColor;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.38);
    hr {
      height: 1px;
      border: none;
      background-color: #e9e9e9;
      margin: 0 0 16px 0;
    }
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    label {
      //styleName: Caption Medium;
      font-family: Graphik;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;

      textarea {
        margin-top: 8px;
        background: #f6f6f6;
        box-shadow: inset -2px 2px 4px rgba(209, 209, 209, 0.2),
          inset 2px -2px 4px rgba(209, 209, 209, 0.2),
          inset -2px -2px 4px rgba(255, 255, 255, 0.9),
          inset 2px 2px 5px rgba(209, 209, 209, 0.9);
        color: $blackColor;
      }
    }
    .close {
      cursor: pointer;
      path {
        fill: $textColor;
      }
      &:active {
        opacity: 0.7;
      }
    }
    label {
      //styleName: Caption Medium;
      font-family: Graphik;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;

      input {
        margin-top: 8px;
        background: #f6f6f6;
        box-shadow: inset -2px 2px 4px rgba(209, 209, 209, 0.2),
          inset 2px -2px 4px rgba(209, 209, 209, 0.2),
          inset -2px -2px 4px rgba(255, 255, 255, 0.9),
          inset 2px 2px 5px rgba(209, 209, 209, 0.9);
        color: $blackColor;
      }
    }
    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close {
        cursor: pointer;
        &:active {
          opacity: 0.7;
        }
      }
    }
  }
}
