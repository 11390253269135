@import "../../../assets/scss/variables";

.feedback {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: 0s linear;
  visibility: hidden;
  hr {
    border: none;
    height: 1px;
    background-color: #e9e9e9;
    opacity: 0.4;
    margin-bottom: 18px;
  }
  * {
    color: $whiteColor;
  }
  &.open {
    background-color: rgba(0, 0, 0, 0.8);
    visibility: visible;
    .inner {
      transform: translate(0, 0);
    }
  }
  .inner {
    background: $textColor;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.38);
    border-radius: 16px 16px 0 0;
    padding: 16px;
    transform: translate(0, 100%);
    transition: 0s linear;
    display: flex;
    flex-direction: column;
    form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close {
        cursor: pointer;
        &:active {
          opacity: 0.7;
        }
      }
    }
    button {
      margin-top: auto;
      background: linear-gradient(
        90deg,
        #97d8d8 0%,
        #5ad7df 100%,
        #17a2c0 100%
      );
      box-shadow: -4px 4px 8px rgba(59, 69, 78, 0.2),
        4px -4px 8px rgba(59, 69, 78, 0.2),
        -4px -4px 8px rgba(89, 103, 118, 0.9),
        4px 4px 10px rgba(59, 69, 78, 0.9);
    }
  }
}
