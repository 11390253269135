@import "../../../../assets/scss/variables";

.modal {
  position:         fixed;
  z-index:          40;
  left:             50%;
  bottom:           0;
  width:            100%;
  max-width:        768px;
  height:           100%;
  transform:        translate(-50%, 0);
  background-color: rgba(0, 0, 0, 0.8);
  display:          flex;
  align-items:      flex-end;
}

.inner {
  background-color: $textColor;
  width:            100%;
  box-shadow:       4px 4px 16px rgba(0, 0, 0, 0.38);
  border-radius:    16px 16px 0 0;
  padding:          0 15px 15px 15px;
  hr {
    border:           none;
    height:           1px;
    background-color: #e9e9e9;
    opacity:          .4;
    margin-bottom:    18px;
  }
  * {
    color: $whiteColor;
  }
  .header {
    padding:         20px 0 14px 0;
    margin-bottom:   0;
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    .close {
      cursor: pointer;
      &:active {
        opacity: .7;
      }
    }
  }
}

.bottom {
  .digit {
    width:           45px;
    height:          45px;
    background:      #4A5662;
    border-radius:   16px;
    font-weight:     500;
    font-size:       20px;
    display:         inline-flex;
    justify-content: center;
    align-items:     center;
    color:           #F1F1F1;
    margin:          0 8px 16px 0;
    box-shadow:      inset -2px 2px 4px rgba(56, 65, 74, 0.2), inset 2px -2px 4px rgba(56, 65, 74, 0.2), inset -2px -2px 4px rgba(92, 107, 122, 0.9), inset 2px 2px 5px rgba(56, 65, 74, 0.9);
  }
  .description {
    color:   $whiteColor;
    opacity: .4;
  }
  .panel {
    margin-top: 18px;
    h4 {
      font-size:     13px;
      margin-bottom: 16px;
      span {
        text-transform: uppercase;
        color:          #bbbbbb;
      }
    }
    label {
      cursor: pointer;
    }
    .something {
      font-size:       12px;
      color:           #eaf2fb;
      text-decoration: underline;
      background:      transparent;
      border:          none;
      box-shadow:      none;
      font-weight:     400;
      margin-bottom:   80px;
      margin-left:     16px;
      &:active {
        text-decoration: none;
      }
    }
  }
}