@import "../../../../assets/scss/variables";

.tickets {
  padding:        70px 15px 16px 15px;
  display:        flex;
  flex-direction: column;
  align-items:    flex-start;
}

.content {
  text-align:  center;
  padding-top: 78px;
  h3 {
    margin-bottom: 8px;
  }
  p {
    font-size:     12px;
    margin-bottom: 0;
  }
  h3, p {
    opacity: .5;
  }
}

.btn {
  max-width: 160px;
  margin:    32px auto 76px auto;
}
