@font-face {
  font-family: 'Graphik';
  src:         url('../fonts/GraphikLight.otf') format('opentype');
  font-weight: 300;
  font-style:  normal;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  src:         url('../fonts/GraphikRegular.otf') format('opentype');
  font-weight: 400;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  src:         url('../fonts/GraphikMedium.otf') format('opentype');
  font-weight: 500;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  src:         url('../fonts/GraphikSemibold.otf') format('opentype');
  font-weight: 600;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'Graphik';
  src:         url('../fonts/GraphikBold.otf') format('opentype');
  font-weight: 700;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Light.ttf') format('truetype');
  font-weight: 300;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'IBM Plex Mono';
  src:         url('../fonts/IBMPlexMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style:  normal;
}
@font-face {
  font-display: swap;
  font-family: 'Inter';
  src:         url('../fonts/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style:  normal;
}


@font-face {
  font-family: 'Montserrat';
  src:         url('../fonts/Montserrat-Light.otf') format('opentype');
  font-weight: 300;
  font-style:  normal;
  font-display: swap;
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  src:         url('../fonts/Montserrat-Regular.otf') format('opentype');
  font-weight: 400;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  src:         url('../fonts/Montserrat-Medium.otf') format('opentype');
  font-weight: 500;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  src:         url('../fonts/Montserrat-SemiBold.otf') format('opentype');
  font-weight: 600;
  font-style:  normal;
}

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  src:         url('../fonts/Montserrat-Bold.otf') format('opentype');
  font-weight: 700;
  font-style:  normal;
}