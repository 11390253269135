@import "../../../assets/scss/variables";

.inner {
  position:       relative;
  display:        flex;
  flex-direction: column;
  flex:           1 1 auto;
  h2 {
    display:     flex;
    align-items: center;
    width:       100%;
    a {
      display: flex;
      img {
        width:        20px;
        margin-right: 14px;
      }
      &:active {
        opacity: .7;
      }
    }
  }
  &:before {
    content:         '';
    position:        absolute;
    z-index:         5;
    left:            0;
    top:             0;
    width:           100%;
    height:          100%;
    background:      url("../../../assets/images/onboarding/noise.png") no-repeat center center;
    background-size: cover;
    opacity:         .15;
  }
  .saly, .numbers {
    position:   absolute;
    z-index:    6;
    left:       50%;
    top:        35%;
    transform:  translate(-50%, -50%);
    width:      84%;
    height:     100%;
    object-fit: contain;
  }
  .numbers {
    width: 60.5%;
    top:   28%;

  }
}

.panel {
  position:        relative;
  z-index:         10;
  margin-top:      auto;
  width:           100%;
  border-radius:   20px 20px 0 0;
  border:          2px solid #eeece9;
  padding:         24px 16px 14px 16px;
  height:          320px;
  display:         flex;
  flex-direction:  column;
  background:      linear-gradient(146.29deg, rgba(236, 233, 230, 0.5) -5.05%, rgba(255, 255, 255, 0.3) 98.78%);
  box-shadow:      0 4px 24px rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(10px);

  form {
    flex:           1 1 auto;
    display:        flex;
    flex-direction: column;
  }
  .digit {
    display:         flex;
    align-items:     center;
    justify-content: space-between;
  }
  .alert {
    margin-left: auto;
    img {
      display: flex;
    }
    &:active {
      opacity: .7;
    }
  }
  .sent {
    font-size:  12px;
    margin-top: 8px;
  }
  .timer {
    color:       $defaultColor;
    margin-left: 8px;
    font-weight: 500;
  }
  button {
    margin-top:     auto;
    pointer-events: none;
    &.active {
      pointer-events: auto;
      background:     linear-gradient(90deg, #97D8D8 0%, #5AD7DF 100%, #17A2C0 100%);
    }
  }
}

.register {
  display:        flex;
  flex-direction: column;
  padding:        0;
  background:     linear-gradient(166.09deg, #757F9A -14.69%, #D7DDE8 49.82%);
  label input {
    background: #e0e2e7;
  }
}

.verify {
  background: linear-gradient(144.47deg, #DAE2F8 -0.83%, #D6A4A4 77.74%);
}

.getStarted, .join {
  background: linear-gradient(22.89deg, #9796F0 -12.75%, #FBC7D4 65.52%, #FEDAA3 114.01%);
  button {
    box-shadow: -5px 5px 10px rgba(184, 177, 203, 0.2), 5px -5px 10px rgba(184, 177, 203, 0.2), -5px -5px 10px rgba(250, 239, 255, 0.9), 5px 5px 13px rgba(184, 177, 203, 0.9), inset 1px 1px 2px rgba(250, 239, 255, 0.3), inset -1px -1px 2px rgba(184, 177, 203, 0.5);
    &.active {
      background: linear-gradient(90deg, #C779D0 12.71%, #4BC0C8 142.38%);
    }
  }
}

.getStarted {
  label input {
    box-shadow:       inset -2px 2px 4px rgba(201, 186, 198, 0.2),
                      inset 2px -2px 4px rgba(201, 186, 198, 0.2),
                      inset -2px -2px 4px rgba(255, 252, 255, 0.9),
                      inset 2px 2px 5px rgba(201, 186, 198, 0.9);
    background-color: #ecdbe9;
  }
}

.join {
  .inner .saly {
    width: 74%;
  }
  .digit label input {
    box-shadow:       inset -2px 2px 4px rgba(174, 166, 191, 0.2),
                      inset 2px -2px 4px rgba(174, 166, 191, 0.2),
                      inset -2px -2px 4px rgba(255, 250, 255, 0.9),
                      inset 2px 2px 5px rgba(174, 166, 191, 0.9);
    background-color: #d9d0ef;
  }
}
