@import "../../../assets/scss/variables";

.card {
  border-radius: 10px;
  background:    linear-gradient(90deg, #757F9A 0%, #D7DDE8 100%);
  padding:       18px 15px 24px 18px;
  margin-bottom: 6px;

  .description {
    display:         flex;
    align-items:     flex-start;
    justify-content: space-between;
    h2 {
      color:         $whiteColor;
      padding-top:   3px;
      margin-bottom: 22px;
    }
    h4 {
      font-weight:   300;
      line-height:   1.4;
      margin-bottom: 0;
      span {
        font-weight: 500;
      }
    }
    img {
      width:       39%;
      margin-left: 10px;
      max-width:   200px;
    }
  }
  .rate {
    margin-top:      16px;
    display:         flex;
    align-items:     center;
    justify-content: space-between;
    padding-right:   5%;
    .star {
      cursor:              pointer;
      width:               26px;
      height:              26px;
      // width:               32px;
      // height:              32px;
      margin-top:          auto;
      background-image:    url('../../../assets/images/star.png');
      background-repeat:   no-repeat;
      background-position: center;
      background-size:     100%;
      margin-right:        8px;
    }
    p {
      margin-left:   5px;
      font-size:     12px;
      color:         $textColor;
      margin-bottom: 0;
    }
  }
  .stars {
    display:        flex;
    flex-direction: row-reverse;
    align-items:    center;
  }
  .stars > .star.active,
  .stars > .star.active ~ .star {
    background-image: url('../../../assets/images/star-yellow-gradient.png');
  }
}
