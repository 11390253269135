@import "../../../assets/scss/variables";

.items {
  margin-top: 8px;
  margin-bottom: 52px;
  &.hide {
    .title {
      svg {
        transform: rotate(180deg);
      }
    }
    .panel {
      display: none;
    }
    .subTitle {
      display: none;
    }
  }
  &.mod {
    margin-top: 21px;
    .item {
      margin-bottom: 6px;
    }
    .title {
      margin-bottom: 20px;
      margin-left: 8px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: $blackColor;
  font-size: 13px;
  &.toggle {
    text-transform: inherit;
    color: $textColor;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 19px;
    font-family: 'Montserrat';
  }
  hr {
    flex: 1 1 auto;
    height: 0.0625rem;
    background: rgba(74, 86, 98, 0.1);
    margin: 0 0.5rem;
    border: none;
    pointer-events: none;
  }
  svg {
    transition: 0.2s linear;
    pointer-events: none;
  }
}
// added by mohit
.count {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  right: 0;
  top: -8px;
  background: #ef4b4b;
  border-radius: 50px;
  font-weight: 600;
  font-size: 10px;
  color: $whiteColor;
  pointer-events: none;
}

.item {
  border-radius: 0.625rem;
  background-color: #f6f6f6;
  box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
    inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
    inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
    inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
  display: flex;
  align-items: center;
  padding: 6px 9px;
  margin-bottom: 1rem;
  min-height: 87px;
  h4 {
    margin-bottom: 0;
  }
  img {
    width: 71px;
    height: 71px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 0.5rem;
  }
  .description {
    flex: 1 1 auto;
    padding-left: 0.5rem;
  }
  .list {
    display: flex;
    align-items: center;
  }
  .price {
    display: flex;
    align-items: center;
    h4 {
      line-height: 1;
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .reducedWidth {
    max-width: calc(100vw - 186px) !important;
  }
  p {
    max-width: calc(100vw - 128px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 300;
  }
  .h3New {
    margin-bottom: 0;
    margin-top: 4px;
  }
}

.panel {
  &.mod {
    padding: 14px 0 14px 0;
    background: transparent;
    box-shadow: inset -3px -3px 7px #ffffff,
      inset 2px 2px 5px rgba(174, 174, 192, 0.4);
    border-radius: 10px;
    .item {
      box-shadow: none;
      min-height: auto;
      position: relative;
      background: transparent;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 0;
      h4,
      h5 {
        font-weight: 400;
        margin-bottom: 0;
      }
      h4 {
        margin-bottom: 5px;
      }
    }
  }
  .link {
    font-size: 12px;
    font-weight: 500;
    color: #76dfe5;
    text-decoration: underline;
    cursor: pointer;
    padding: 4px 10px;
    &:active {
      text-decoration: none;
    }
  }
}
