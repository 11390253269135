// @import "../../../assets/scss/variables";

// .add {
//   border: none;
//   border-radius: 0.625rem;
//   box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
//     0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
//     -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
//     0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
//   display: flex;
//   align-items: center;
//   padding: 0.25rem;
//   font-size: 0.8125rem;
//   font-weight: 500;
//   background: #f0f0f3;
//   width: 72px;
//   &.mod {
//     width: auto;
//   }
//   &:active {
//     color: $defaultColor;
//   }
//   span {
//     font-size: 1rem;
//     border-radius: 50%;
//     box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
//       inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
//       inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
//       inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
//     color: #3cbcb4;
//     background-color: $backgroundColor;
//     width: 24px;
//     height: 24px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     margin-right: 0.5rem;
//     &.minus {
//       margin-right: 14px;
//       margin-left: 0;
//       cursor: pointer;
//     }

//     &.plus {
//       margin-right: 0;
//       margin-left: 14px;
//       cursor: pointer;
//     }
//   }
// }
@import "../../../assets/scss/variables";

.add {
  border: none;
  border-radius: 0.625rem;
  box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
    0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
    -0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
    0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
  display: flex;
  align-items: center;
  padding: 0.25rem;
  font-size: 0.8125rem;
  font-weight: 500;
  background: #f0f0f3;
  width: 72px;
  user-select: none;
  &.mod {
    width: auto;
    .plus {
      margin-right: 0;
      margin-left: 14px;
    }
  }
  .plus,
  .minus {
    font-size: 1rem;
    border-radius: 50%;
    box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
      inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
      inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
      inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
    color: #3cbcb4;
    background-color: $backgroundColor;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    margin-left: 0;
    cursor: pointer;
    position: relative;
    &:active {
      opacity: 0.7;
    }
    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 2px;
      background: url("../../../assets/images/icons/minus.svg") no-repeat center
        center;
      background-size: contain;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .plus {
    &:before {
      height: 10px;
      background: url("../../../assets/images/icons/plus.svg") no-repeat center
        center;
      background-size: contain;
    }
  }
  &.register {
    margin-left: auto;
    width: 102px;
    span {
      background: -webkit-linear-gradient(
        45deg,
        rgba(199, 121, 208, 1),
        rgba(199, 121, 208, 1),
        rgba(75, 192, 200, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:active {
      box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
        inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
        inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
        inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9) !important;
    }
    .plus {
      margin-right: 4px;
      &:before {
        background: url("../../../assets/images/icons/plus-gradient.svg")
          no-repeat center center !important;
        background-size: contain;
      }
    }
  }
}
