@import "../../../assets/scss/variables";

.footer {
	background: $backgroundColor;
	padding: 16px 32px;
	position: fixed;
	z-index: 10;
	margin: 0 auto;
	width: 100%;
	max-width: 768px;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 0);
	.menuBtn {
		max-width: 70px;
		text-align: center;
		position: fixed;
		bottom: 4.5rem;
		left: 50%;
		transform: translate(-50%, 0);
		font-weight: 500;
		background-color: $textColor;
		box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.25);
		padding: 17px;
		font-size: 13px;
		text-transform: inherit;
	}
	.nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		li {
			width: 2rem;
			height: 2rem;
			a {
				border-radius: 0.625rem;
				box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
					0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
					-0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
					0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
				&:active {
					box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
						inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
						inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
						inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
				}
			}
			span {
				border-radius: 0.625rem;
				box-shadow: -0.3125rem 0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
					0.3125rem -0.3125rem 0.625rem 0 rgba(221, 221, 224, 0.2),
					-0.3125rem -0.3125rem 0.625rem 0 rgba(255, 255, 255, 0.9),
					0.3125rem 0.3125rem 0.8125rem 0 rgba(220, 220, 220, 0.9);
				&:active {
					box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
						inset 0.125rem -0.125rem 0.25rem 0 rgba(221, 221, 224, 0.2),
						inset -0.125rem -0.125rem 0.25rem 0 rgba(255, 255, 255, 0.9),
						inset 0.125rem 0.125rem 0.3125rem 0 rgba(220, 220, 220, 0.9);
				}
			}
			&.notEmpty {
				position: relative;
				svg path {
					stroke: $textColor;
				}
				.count {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					width: 20px;
					height: 20px;
					right: -6px;
					top: -8px;
					background: #ef4b4b;
					border-radius: 50px;
					font-weight: 600;
					font-size: 10px;
					color: $whiteColor;
					pointer-events: none;
				}
			}
			a {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				transition: 0.2s linear;
			}
			span {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				transition: 0.2s linear;
			}
		}
	}
}

.menu {
	position: fixed;
	z-index: 40;
	top: 0;
	left: 50%;
	scroll-behavior: smooth;
	width: 100%;
	max-width: 768px;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	transition: 0.2s linear;
	visibility: hidden;
	transform: translate(-50%, 0);
	&.open {
		background-color: rgba(0, 0, 0, 0.8);
		visibility: visible;
		.inner {
			transform: translate(0, 0);
		}
	}
	.inner {
		background: $textColor;
		box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.38);
		border-radius: 16px 16px 0 0;
		padding: 16px 16px 0 16px;
		transform: translate(0, 100%);
		transition: 0.2s linear;
		display: flex;
		flex-direction: column;
		hr {
			border: none;
			height: 1px;
			background-color: #e9e9e9;
			opacity: 0.4;
			margin-bottom: 22px;
		}
		* {
			color: $whiteColor;
		}
		h3 {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 10px;
			.close {
				cursor: pointer;
				&:active {
					opacity: 0.7;
				}
			}
		}
		.availability {
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 14px;
				font-weight: 500;
				line-height: 2.1;
				margin-bottom: 19px;
			}
			.radio {
				background: $textColor;
				box-shadow: inset -2px 2px 4px rgba(59, 69, 78, 0.2),
					inset 2px -2px 4px rgba(59, 69, 78, 0.2),
					inset -2px -2px 4px rgba(89, 103, 118, 0.9),
					inset 2px 2px 5px rgba(59, 69, 78, 0.9);
				border-radius: 12px;
				width: 40px;
				height: 24px;
				position: relative;
				cursor: pointer;
				margin-bottom: 0;
				&:active {
					opacity: 0.7;
				}
				span {
					left: 7%;
					top: 50%;
					position: absolute;
					z-index: 1;
					border-radius: 12px;
					width: 18px;
					height: 18px;
					transform: translate(0, -50%);
					background: #657280;
					box-shadow: -2px 2px 4px rgba(50, 58, 67, 0.2),
						2px -2px 4px rgba(50, 58, 67, 0.2),
						-2px -2px 4px rgba(98, 114, 129, 0.9),
						2px 2px 5px rgba(50, 58, 67, 0.9);
					transition: 0.2s linear;
				}
				input:checked + span {
					left: 45%;
					background: linear-gradient(
						90deg,
						#97d8d8 0%,
						#5ad7df 100%,
						#17a2c0 100%
					);
					box-shadow: -2px 2px 4px rgba(50, 58, 67, 0.2),
						2px -2px 4px rgba(50, 58, 67, 0.2),
						-2px -2px 4px rgba(98, 114, 129, 0.9),
						2px 2px 5px rgba(50, 58, 67, 0.9);
				}
			}
		}
	}
}
