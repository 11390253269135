@import "../../../../assets/scss/variables";

.modal {
  position:         fixed;
  z-index:          40;
  left:             50%;
  top:              0;
  width:            100%;
  height:           100%;
  background-color: $textColor;
  max-width:        768px;
  transform:        translate(-50%, 0);
  .back {
    cursor:          pointer;
    position:        absolute;
    z-index:         1;
    left:            8px;
    top:             16px;
    width:           30px;
    height:          30px;
    border-radius:   10px;
    background:      #657280;
    box-shadow:      -4px 4px 8px rgba(91, 103, 115, 0.2),
                     4px -4px 8px rgba(91, 103, 115, 0.2),
                     -4px -4px 8px rgba(111, 125, 141, 0.9),
                     4px 4px 10px rgba(91, 103, 115, 0.9);
    display:         flex;
    align-items:     center;
    justify-content: center;
    transition:      .2s linear;
    margin-right:    8px;
    svg path {
      fill: $whiteColor;
    }
    &:active {
      box-shadow: inset -0.125rem 0.125rem 0.25rem 0 rgba(91, 103, 115, 0.2),
                  inset 0.125rem -0.125rem 0.25rem 0 rgba(91, 103, 115, 0.2),
                  inset -0.125rem -0.125rem 0.25rem 0 rgba(111, 125, 141, 0.9),
                  inset 0.125rem 0.125rem 0.3125rem 0 rgba(91, 103, 115, 0.9);
    }
  }
  .top {
    background-color: #657280;
    height:           46dvh;
    width:            100%;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    img {
      width:   100%;
      height:  100%;
      opacity: .25;
      object-fit: cover !important;
    }
  }
  * {
    color: $whiteColor;
  }
}

.bottom {
  padding:  16px 16px 0px 16px;
  height:   calc(54dvh - 54px);
  overflow: auto;
  .itemTitle {
    display:     flex;
    align-items: center;
    color:       $whiteColor;
  }
  .chip {
    display:        inline-block;
    padding:        8px;
    box-shadow:     inset -2px 2px 4px rgba(67, 77, 88, 0.2), inset 2px -2px 4px rgba(67, 77, 88, 0.2), inset -2px -2px 4px rgba(81, 95, 108, 0.9), inset 2px 2px 5px rgba(67, 77, 88, 0.9);
    border-radius:  16px;
    font-size:      10px;
    text-transform: uppercase;
    font-weight:    500;
    margin:         0 8px 14px 0;
    color:          #F1F1F1;
  }
  p {
    opacity:       .4;
    margin-bottom: 21px;
  }
  .option {
    margin-bottom: 20px;
    h4 {
      font-size:     13px;
      margin-bottom: 20px;
      span {
        text-transform: uppercase;
        color:          #bbbbbb;
        font-size:      10px;
      }
    }
    label {
      cursor:        pointer;
      margin-bottom: 16px;
    }
    .price {
      font-size:   10px;
      color:       #bbbbbb;
      margin-left: auto;
    }
  }
  .more {
    color:     #45dfd5;
    font-size: 12px;
  }
}