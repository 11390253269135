.app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
}

main {
  width: 100%;
  flex: 1 0 auto;
  padding: 0px 16px 116px 16px;
  // padding: 106px 16px 116px 16px;
}
